/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { BodyType, ErrorType } from './axios';
import { request } from './axios';
import { transformQueryOptions } from './transformQueryOptions';
export type RedirectByDataProjectGrantIdParams = {
  redirectUri: string;
  error?: string;
  state?: string;
};

export type AuthorizeParams = {
  redirectUri: string;
  state?: string;
  healthcareProviderId: string;
};

export type RedirectByDataProjectIdParams = {
  redirectUri: string;
  error?: string;
  state?: string;
};

export type ReadDataProjectsParams = {
  searchText?: string;
  grantedForHealthcareProviderId?: string;
};

export type ReadPatientDataForValidationParams = {
  healthcareProviderId: string;
  pid: string;
};

export type DataProjectAccessDetailsOfHealthcareProviderParams = {
  healthcareProviderId: string;
};

export type DataProjectAccessOfHealthcareProviderParams = {
  healthcareProviderId: string;
  olderThan: string;
  pageSize: number;
};

export type LoginOptions200Item = (typeof LoginOptions200Item)[keyof typeof LoginOptions200Item];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginOptions200Item = {
  PASSWORD: 'PASSWORD',
  HIN: 'HIN',
} as const;

export type RevokeGrantsOfDataProjectReasonsItem =
  (typeof RevokeGrantsOfDataProjectReasonsItem)[keyof typeof RevokeGrantsOfDataProjectReasonsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RevokeGrantsOfDataProjectReasonsItem = {
  SERVICE_DID_NOT_WORK: 'SERVICE_DID_NOT_WORK',
  SERVICE_WAS_NOT_USEFUL: 'SERVICE_WAS_NOT_USEFUL',
  NO_LONGER_NEED_SERVICE: 'NO_LONGER_NEED_SERVICE',
  TO_TEST: 'TO_TEST',
} as const;

export type RevokeGrantsOfDataProjectParams = {
  healthcareProviderId: string;
  reasons?: RevokeGrantsOfDataProjectReasonsItem[];
  reasonComment?: string;
};

export type SetGrantsOfDataProjectParams = {
  healthcareProviderId: string;
};

export type GetGrantsOfDataProjectParams = {
  healthcareProviderId: string;
};

export interface DataProjectScopeRequestGrantsWithRelatedIds {
  dataProjectGranted: boolean;
  dataProjectId: string;
  grantedScopeRequestIds: string[];
  healthcareProviderId: string;
}

export interface Unit {
  [key: string]: unknown;
}

export interface DataProjectScopeRequestGrants {
  dataProjectGranted: boolean;
  grantedScopeRequestIds: string[];
}

export type PortalScopeRequestType = (typeof PortalScopeRequestType)[keyof typeof PortalScopeRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalScopeRequestType = {
  PATIENT: 'PATIENT',
  MEDICATION_STATEMENT: 'MEDICATION_STATEMENT',
  CONDITION: 'CONDITION',
  OBSERVATION: 'OBSERVATION',
  APPOINTMENT: 'APPOINTMENT',
} as const;

export type PortalScopeRequestOperationsItem =
  (typeof PortalScopeRequestOperationsItem)[keyof typeof PortalScopeRequestOperationsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalScopeRequestOperationsItem = {
  READ: 'READ',
  SEARCH: 'SEARCH',
  EXTENDED_OPERATION_TYPE: 'EXTENDED_OPERATION_TYPE',
} as const;

export interface PortalScopeRequest {
  dataProjectId: string;
  id: string;
  operations: PortalScopeRequestOperationsItem[];
  optional: boolean;
  resourceString: string;
  type: PortalScopeRequestType;
}

export interface PortalDataProjectPreview {
  description: string;
  preview: string;
}

export interface PortalDataProjectCategory {
  key: string;
  name: string;
}

export interface PortalDataProjectWithScopesDto {
  categories: PortalDataProjectCategory[];
  dataUsage?: string;
  dataVisibility?: string;
  descriptionLong?: string;
  descriptionShort: string;
  homepage?: string;
  id: string;
  inPreview: boolean;
  languages?: string;
  launchUrl?: string;
  logo?: string;
  name: string;
  patientConsent?: string;
  previews: PortalDataProjectPreview[];
  primaryContact: string;
  primaryContactEmail: string;
  primaryContactPhone?: string;
  provider: string;
  scopes: PortalScopeRequest[];
}

export interface PortalDataProject {
  categories: PortalDataProjectCategory[];
  descriptionLong?: string;
  descriptionShort: string;
  homepage?: string;
  id: string;
  inPreview: boolean;
  languages?: string;
  launchUrl?: string;
  logo?: string;
  name: string;
  previews: PortalDataProjectPreview[];
  primaryContact: string;
  primaryContactEmail: string;
  primaryContactPhone?: string;
  provider: string;
}

export interface PidBlockDto {
  comment: string;
  pid: string;
}

export interface JsonNode {
  [key: string]: unknown;
}

export type BusinessAuditEventDtoEventType =
  (typeof BusinessAuditEventDtoEventType)[keyof typeof BusinessAuditEventDtoEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessAuditEventDtoEventType = {
  NEW_HEALTHCARE_PROVIDER_USER: 'NEW_HEALTHCARE_PROVIDER_USER',
  DELETE_HEALTHCARE_PROVIDER_USER: 'DELETE_HEALTHCARE_PROVIDER_USER',
  CREATE_DATA_PROJECT_GRANT: 'CREATE_DATA_PROJECT_GRANT',
  UPDATE_DATA_PROJECT_GRANT: 'UPDATE_DATA_PROJECT_GRANT',
  REVOKE_DATA_PROJECT_GRANT: 'REVOKE_DATA_PROJECT_GRANT',
  CREATE_PID_BLOCK: 'CREATE_PID_BLOCK',
  DELETE_PID_BLOCK: 'DELETE_PID_BLOCK',
} as const;

export interface BusinessAuditEventDto {
  created: string;
  data: JsonNode;
  dataProjectId?: string;
  eventType: BusinessAuditEventDtoEventType;
  healthcareProviderId: string;
  id: string;
  username: string;
}

export type DataProjectAccessLogResourceType =
  (typeof DataProjectAccessLogResourceType)[keyof typeof DataProjectAccessLogResourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataProjectAccessLogResourceType = {
  PATIENT: 'PATIENT',
  MEDICATION_STATEMENT: 'MEDICATION_STATEMENT',
  CONDITION: 'CONDITION',
  OBSERVATION: 'OBSERVATION',
  APPOINTMENT: 'APPOINTMENT',
} as const;

export type DataProjectAccessLogOperationType =
  (typeof DataProjectAccessLogOperationType)[keyof typeof DataProjectAccessLogOperationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataProjectAccessLogOperationType = {
  READ: 'READ',
  SEARCH: 'SEARCH',
  EXTENDED_OPERATION_TYPE: 'EXTENDED_OPERATION_TYPE',
} as const;

export interface DataProjectAccessLog {
  id: string;
  loggedAt: string;
  operationType: DataProjectAccessLogOperationType;
  patientId?: string;
  resourceType: DataProjectAccessLogResourceType;
}

export interface RequestContextUserDto {
  name: string;
  role: string;
}

export interface RequestContextDto {
  dataProjectId: string;
  oldestTimestamp: string;
  patientId?: string;
  requestContextId: string;
  requestContextType: string;
  user?: RequestContextUserDto;
}

export interface PageResultRequestContextDto {
  next?: string;
  oldest?: string;
  result: RequestContextDto[];
}

export type PisDtoType = (typeof PisDtoType)[keyof typeof PisDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PisDtoType = {
  AESKULAP: 'AESKULAP',
  VITOMEDV2: 'VITOMEDV2',
  AXENITA: 'AXENITA',
  ELEXIS: 'ELEXIS',
  ELEXIS_DATABASE: 'ELEXIS_DATABASE',
  MOCK: 'MOCK',
  SIMED: 'SIMED',
  CURAMED: 'CURAMED',
  TOMEDO: 'TOMEDO',
  WINMED: 'WINMED',
  PEX_II: 'PEX_II',
} as const;

export type PisDtoSupportedResourceTypesItem =
  (typeof PisDtoSupportedResourceTypesItem)[keyof typeof PisDtoSupportedResourceTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PisDtoSupportedResourceTypesItem = {
  PATIENT: 'PATIENT',
  MEDICATION_STATEMENT: 'MEDICATION_STATEMENT',
  CONDITION: 'CONDITION',
  OBSERVATION: 'OBSERVATION',
  APPOINTMENT: 'APPOINTMENT',
} as const;

export type PisDtoImplementationType = (typeof PisDtoImplementationType)[keyof typeof PisDtoImplementationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PisDtoImplementationType = {
  DATABASE: 'DATABASE',
  HTTP: 'HTTP',
  MOCK: 'MOCK',
} as const;

export interface PisDto {
  implementationType: PisDtoImplementationType;
  supportedResourceTypes: PisDtoSupportedResourceTypesItem[];
  type: PisDtoType;
}

export interface Whoami {
  email?: string;
  healthcareProviderId?: string;
  name: string;
  organization?: string;
  picture?: string;
  pis?: PisDto;
  userId?: string;
}

export interface PatchPidBlockDto {
  /**
   * @minLength 0
   * @maxLength 1000
   */
  comment: string;
}

export interface CreatePidBlockDto {
  /**
   * @minLength 0
   * @maxLength 1000
   */
  comment: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  pid: string;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getGrantsOfDataProject = (
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DataProjectScopeRequestGrants>(
    { url: `/portal-api/data-projects/${id}/grants`, method: 'GET', params, signal },
    options,
  );
};

export const getGetGrantsOfDataProjectQueryKey = (id: string, params: GetGrantsOfDataProjectParams) => {
  return [`/portal-api/data-projects/${id}/grants`, ...(params ? [params] : [])] as const;
};

export const useGetGrantsOfDataProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGrantsOfDataProjectQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGrantsOfDataProject>>> = ({ signal }) =>
    getGrantsOfDataProject(id, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetGrantsOfDataProjectQueryResult = NonNullable<Awaited<ReturnType<typeof getGrantsOfDataProject>>>;
export type GetGrantsOfDataProjectQueryError = ErrorType<unknown>;

export function useGetGrantsOfDataProject<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsOfDataProject<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsOfDataProject<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGrantsOfDataProject<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetGrantsOfDataProjectQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useGetGrantsOfDataProjectSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGrantsOfDataProjectQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGrantsOfDataProject>>> = ({ signal }) =>
    getGrantsOfDataProject(id, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetGrantsOfDataProjectSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getGrantsOfDataProject>>>;
export type GetGrantsOfDataProjectSuspenseQueryError = ErrorType<unknown>;

export function useGetGrantsOfDataProjectSuspense<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsOfDataProjectSuspense<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsOfDataProjectSuspense<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGrantsOfDataProjectSuspense<
  TData = Awaited<ReturnType<typeof getGrantsOfDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: GetGrantsOfDataProjectParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsOfDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetGrantsOfDataProjectSuspenseQueryOptions(id, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const setGrantsOfDataProject = (
  id: string,
  setGrantsOfDataProjectBody: BodyType<string[]>,
  params: SetGrantsOfDataProjectParams,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    {
      url: `/portal-api/data-projects/${id}/grants`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: setGrantsOfDataProjectBody,
      params,
    },
    options,
  );
};

export const getSetGrantsOfDataProjectMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setGrantsOfDataProject>>,
    TError,
    { id: string; data: BodyType<string[]>; params: SetGrantsOfDataProjectParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setGrantsOfDataProject>>,
  TError,
  { id: string; data: BodyType<string[]>; params: SetGrantsOfDataProjectParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setGrantsOfDataProject>>,
    { id: string; data: BodyType<string[]>; params: SetGrantsOfDataProjectParams }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return setGrantsOfDataProject(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetGrantsOfDataProjectMutationResult = NonNullable<Awaited<ReturnType<typeof setGrantsOfDataProject>>>;
export type SetGrantsOfDataProjectMutationBody = BodyType<string[]>;
export type SetGrantsOfDataProjectMutationError = ErrorType<unknown>;

export const useSetGrantsOfDataProject = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setGrantsOfDataProject>>,
    TError,
    { id: string; data: BodyType<string[]>; params: SetGrantsOfDataProjectParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setGrantsOfDataProject>>,
  TError,
  { id: string; data: BodyType<string[]>; params: SetGrantsOfDataProjectParams },
  TContext
> => {
  const mutationOptions = getSetGrantsOfDataProjectMutationOptions(options);

  return useMutation(mutationOptions);
};

export const revokeGrantsOfDataProject = (
  id: string,
  params: RevokeGrantsOfDataProjectParams,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>({ url: `/portal-api/data-projects/${id}/grants`, method: 'DELETE', params }, options);
};

export const getRevokeGrantsOfDataProjectMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeGrantsOfDataProject>>,
    TError,
    { id: string; params: RevokeGrantsOfDataProjectParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof revokeGrantsOfDataProject>>,
  TError,
  { id: string; params: RevokeGrantsOfDataProjectParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof revokeGrantsOfDataProject>>,
    { id: string; params: RevokeGrantsOfDataProjectParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return revokeGrantsOfDataProject(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeGrantsOfDataProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof revokeGrantsOfDataProject>>
>;

export type RevokeGrantsOfDataProjectMutationError = ErrorType<unknown>;

export const useRevokeGrantsOfDataProject = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeGrantsOfDataProject>>,
    TError,
    { id: string; params: RevokeGrantsOfDataProjectParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof revokeGrantsOfDataProject>>,
  TError,
  { id: string; params: RevokeGrantsOfDataProjectParams },
  TContext
> => {
  const mutationOptions = getRevokeGrantsOfDataProjectMutationOptions(options);

  return useMutation(mutationOptions);
};

export const readBlockedPids = (
  healthcareProviderId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PidBlockDto[]>(
    { url: `/portal-api/healthcare-provider/${healthcareProviderId}/pid-block`, method: 'GET', signal },
    options,
  );
};

export const getReadBlockedPidsQueryKey = (healthcareProviderId: string) => {
  return [`/portal-api/healthcare-provider/${healthcareProviderId}/pid-block`] as const;
};

export const useReadBlockedPidsQueryOptions = <
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadBlockedPidsQueryKey(healthcareProviderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readBlockedPids>>> = ({ signal }) =>
    readBlockedPids(healthcareProviderId, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadBlockedPidsQueryResult = NonNullable<Awaited<ReturnType<typeof readBlockedPids>>>;
export type ReadBlockedPidsQueryError = ErrorType<unknown>;

export function useReadBlockedPids<TData = Awaited<ReturnType<typeof readBlockedPids>>, TError = ErrorType<unknown>>(
  healthcareProviderId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadBlockedPids<TData = Awaited<ReturnType<typeof readBlockedPids>>, TError = ErrorType<unknown>>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadBlockedPids<TData = Awaited<ReturnType<typeof readBlockedPids>>, TError = ErrorType<unknown>>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadBlockedPids<TData = Awaited<ReturnType<typeof readBlockedPids>>, TError = ErrorType<unknown>>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadBlockedPidsQueryOptions(healthcareProviderId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useReadBlockedPidsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadBlockedPidsQueryKey(healthcareProviderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readBlockedPids>>> = ({ signal }) =>
    readBlockedPids(healthcareProviderId, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadBlockedPidsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof readBlockedPids>>>;
export type ReadBlockedPidsSuspenseQueryError = ErrorType<unknown>;

export function useReadBlockedPidsSuspense<
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadBlockedPidsSuspense<
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadBlockedPidsSuspense<
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadBlockedPidsSuspense<
  TData = Awaited<ReturnType<typeof readBlockedPids>>,
  TError = ErrorType<unknown>,
>(
  healthcareProviderId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readBlockedPids>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadBlockedPidsSuspenseQueryOptions(healthcareProviderId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createBlockedPid = (
  healthcareProviderId: string,
  createPidBlockDto: BodyType<CreatePidBlockDto>,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    {
      url: `/portal-api/healthcare-provider/${healthcareProviderId}/pid-block`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPidBlockDto,
    },
    options,
  );
};

export const getCreateBlockedPidMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBlockedPid>>,
    TError,
    { healthcareProviderId: string; data: BodyType<CreatePidBlockDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createBlockedPid>>,
  TError,
  { healthcareProviderId: string; data: BodyType<CreatePidBlockDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBlockedPid>>,
    { healthcareProviderId: string; data: BodyType<CreatePidBlockDto> }
  > = (props) => {
    const { healthcareProviderId, data } = props ?? {};

    return createBlockedPid(healthcareProviderId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateBlockedPidMutationResult = NonNullable<Awaited<ReturnType<typeof createBlockedPid>>>;
export type CreateBlockedPidMutationBody = BodyType<CreatePidBlockDto>;
export type CreateBlockedPidMutationError = ErrorType<unknown>;

export const useCreateBlockedPid = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBlockedPid>>,
    TError,
    { healthcareProviderId: string; data: BodyType<CreatePidBlockDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createBlockedPid>>,
  TError,
  { healthcareProviderId: string; data: BodyType<CreatePidBlockDto> },
  TContext
> => {
  const mutationOptions = getCreateBlockedPidMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteBlockedPid = (
  healthcareProviderId: string,
  pid: string,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    { url: `/portal-api/healthcare-provider/${healthcareProviderId}/pid-block/${pid}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteBlockedPidMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBlockedPid>>,
    TError,
    { healthcareProviderId: string; pid: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteBlockedPid>>,
  TError,
  { healthcareProviderId: string; pid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteBlockedPid>>,
    { healthcareProviderId: string; pid: string }
  > = (props) => {
    const { healthcareProviderId, pid } = props ?? {};

    return deleteBlockedPid(healthcareProviderId, pid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteBlockedPidMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBlockedPid>>>;

export type DeleteBlockedPidMutationError = ErrorType<unknown>;

export const useDeleteBlockedPid = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBlockedPid>>,
    TError,
    { healthcareProviderId: string; pid: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteBlockedPid>>,
  TError,
  { healthcareProviderId: string; pid: string },
  TContext
> => {
  const mutationOptions = getDeleteBlockedPidMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateBlockedPid = (
  healthcareProviderId: string,
  pid: string,
  patchPidBlockDto: BodyType<PatchPidBlockDto>,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    {
      url: `/portal-api/healthcare-provider/${healthcareProviderId}/pid-block/${pid}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchPidBlockDto,
    },
    options,
  );
};

export const getUpdateBlockedPidMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBlockedPid>>,
    TError,
    { healthcareProviderId: string; pid: string; data: BodyType<PatchPidBlockDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBlockedPid>>,
  TError,
  { healthcareProviderId: string; pid: string; data: BodyType<PatchPidBlockDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBlockedPid>>,
    { healthcareProviderId: string; pid: string; data: BodyType<PatchPidBlockDto> }
  > = (props) => {
    const { healthcareProviderId, pid, data } = props ?? {};

    return updateBlockedPid(healthcareProviderId, pid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBlockedPidMutationResult = NonNullable<Awaited<ReturnType<typeof updateBlockedPid>>>;
export type UpdateBlockedPidMutationBody = BodyType<PatchPidBlockDto>;
export type UpdateBlockedPidMutationError = ErrorType<unknown>;

export const useUpdateBlockedPid = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBlockedPid>>,
    TError,
    { healthcareProviderId: string; pid: string; data: BodyType<PatchPidBlockDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateBlockedPid>>,
  TError,
  { healthcareProviderId: string; pid: string; data: BodyType<PatchPidBlockDto> },
  TContext
> => {
  const mutationOptions = getUpdateBlockedPidMutationOptions(options);

  return useMutation(mutationOptions);
};

export const whoami = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<Whoami>({ url: `/portal-api/whoami`, method: 'GET', signal }, options);
};

export const getWhoamiQueryKey = () => {
  return [`/portal-api/whoami`] as const;
};

export const useWhoamiQueryOptions = <
  TData = Awaited<ReturnType<typeof whoami>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWhoamiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof whoami>>> = ({ signal }) => whoami(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData> & { queryKey: QueryKey };
};

export type WhoamiQueryResult = NonNullable<Awaited<ReturnType<typeof whoami>>>;
export type WhoamiQueryError = ErrorType<unknown>;

export function useWhoami<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useWhoami<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useWhoami<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useWhoami<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useWhoamiQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useWhoamiSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof whoami>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWhoamiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof whoami>>> = ({ signal }) => whoami(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type WhoamiSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof whoami>>>;
export type WhoamiSuspenseQueryError = ErrorType<unknown>;

export function useWhoamiSuspense<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options: {
  query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useWhoamiSuspense<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useWhoamiSuspense<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useWhoamiSuspense<TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useWhoamiSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const loginOptions = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<LoginOptions200Item[]>({ url: `/portal-api/login-options`, method: 'GET', signal }, options);
};

export const getLoginOptionsQueryKey = () => {
  return [`/portal-api/login-options`] as const;
};

export const useLoginOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoginOptionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loginOptions>>> = ({ signal }) =>
    loginOptions(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type LoginOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof loginOptions>>>;
export type LoginOptionsQueryError = ErrorType<unknown>;

export function useLoginOptions<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useLoginOptions<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useLoginOptions<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useLoginOptions<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useLoginOptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useLoginOptionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoginOptionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loginOptions>>> = ({ signal }) =>
    loginOptions(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type LoginOptionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof loginOptions>>>;
export type LoginOptionsSuspenseQueryError = ErrorType<unknown>;

export function useLoginOptionsSuspense<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useLoginOptionsSuspense<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useLoginOptionsSuspense<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useLoginOptionsSuspense<
  TData = Awaited<ReturnType<typeof loginOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof loginOptions>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useLoginOptionsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const dataProjectAccessOfHealthcareProvider = (
  params: DataProjectAccessOfHealthcareProviderParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PageResultRequestContextDto>(
    { url: `/portal-api/log/data-project-access`, method: 'GET', params, signal },
    options,
  );
};

export const getDataProjectAccessOfHealthcareProviderQueryKey = (
  params: DataProjectAccessOfHealthcareProviderParams,
) => {
  return [`/portal-api/log/data-project-access`, ...(params ? [params] : [])] as const;
};

export const useDataProjectAccessOfHealthcareProviderSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  >,
  TError = ErrorType<unknown>,
>(
  params: DataProjectAccessOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        TError,
        TData,
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        QueryKey,
        DataProjectAccessOfHealthcareProviderParams['olderThan']
      >
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDataProjectAccessOfHealthcareProviderQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    QueryKey,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  > = ({ signal, pageParam }) =>
    dataProjectAccessOfHealthcareProvider(
      { ...params, olderThan: pageParam || params?.['olderThan'] },
      requestOptions,
      signal,
    );

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    TError,
    TData,
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    QueryKey,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  > & { queryKey: QueryKey };
};

export type DataProjectAccessOfHealthcareProviderSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>
>;
export type DataProjectAccessOfHealthcareProviderSuspenseInfiniteQueryError = ErrorType<unknown>;

export function useDataProjectAccessOfHealthcareProviderSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  >,
  TError = ErrorType<unknown>,
>(
  params: DataProjectAccessOfHealthcareProviderParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        TError,
        TData,
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        QueryKey,
        DataProjectAccessOfHealthcareProviderParams['olderThan']
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessOfHealthcareProviderSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  >,
  TError = ErrorType<unknown>,
>(
  params: DataProjectAccessOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        TError,
        TData,
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        QueryKey,
        DataProjectAccessOfHealthcareProviderParams['olderThan']
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessOfHealthcareProviderSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  >,
  TError = ErrorType<unknown>,
>(
  params: DataProjectAccessOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        TError,
        TData,
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        QueryKey,
        DataProjectAccessOfHealthcareProviderParams['olderThan']
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDataProjectAccessOfHealthcareProviderSuspenseInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
    DataProjectAccessOfHealthcareProviderParams['olderThan']
  >,
  TError = ErrorType<unknown>,
>(
  params: DataProjectAccessOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        TError,
        TData,
        Awaited<ReturnType<typeof dataProjectAccessOfHealthcareProvider>>,
        QueryKey,
        DataProjectAccessOfHealthcareProviderParams['olderThan']
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useDataProjectAccessOfHealthcareProviderSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(queryOptions) as UseSuspenseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const dataProjectAccessDetailsOfHealthcareProvider = (
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DataProjectAccessLog[]>(
    { url: `/portal-api/log/data-project-access/${id}`, method: 'GET', params, signal },
    options,
  );
};

export const getDataProjectAccessDetailsOfHealthcareProviderQueryKey = (
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
) => {
  return [`/portal-api/log/data-project-access/${id}`, ...(params ? [params] : [])] as const;
};

export const useDataProjectAccessDetailsOfHealthcareProviderQueryOptions = <
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDataProjectAccessDetailsOfHealthcareProviderQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>> = ({
    signal,
  }) => dataProjectAccessDetailsOfHealthcareProvider(id, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataProjectAccessDetailsOfHealthcareProviderQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>
>;
export type DataProjectAccessDetailsOfHealthcareProviderQueryError = ErrorType<unknown>;

export function useDataProjectAccessDetailsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessDetailsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessDetailsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDataProjectAccessDetailsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useDataProjectAccessDetailsOfHealthcareProviderQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useDataProjectAccessDetailsOfHealthcareProviderSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDataProjectAccessDetailsOfHealthcareProviderQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>> = ({
    signal,
  }) => dataProjectAccessDetailsOfHealthcareProvider(id, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DataProjectAccessDetailsOfHealthcareProviderSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>
>;
export type DataProjectAccessDetailsOfHealthcareProviderSuspenseQueryError = ErrorType<unknown>;

export function useDataProjectAccessDetailsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessDetailsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDataProjectAccessDetailsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDataProjectAccessDetailsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params: DataProjectAccessDetailsOfHealthcareProviderParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof dataProjectAccessDetailsOfHealthcareProvider>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useDataProjectAccessDetailsOfHealthcareProviderSuspenseQueryOptions(id, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const auditEventsOfHealthcareProvider = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<BusinessAuditEventDto[]>({ url: `/portal-api/log/audit`, method: 'GET', signal }, options);
};

export const getAuditEventsOfHealthcareProviderQueryKey = () => {
  return [`/portal-api/log/audit`] as const;
};

export const useAuditEventsOfHealthcareProviderQueryOptions = <
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuditEventsOfHealthcareProviderQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>> = ({ signal }) =>
    auditEventsOfHealthcareProvider(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuditEventsOfHealthcareProviderQueryResult = NonNullable<
  Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>
>;
export type AuditEventsOfHealthcareProviderQueryError = ErrorType<unknown>;

export function useAuditEventsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuditEventsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuditEventsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useAuditEventsOfHealthcareProvider<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useAuditEventsOfHealthcareProviderQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useAuditEventsOfHealthcareProviderSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuditEventsOfHealthcareProviderQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>> = ({ signal }) =>
    auditEventsOfHealthcareProvider(requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuditEventsOfHealthcareProviderSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>
>;
export type AuditEventsOfHealthcareProviderSuspenseQueryError = ErrorType<unknown>;

export function useAuditEventsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuditEventsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuditEventsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useAuditEventsOfHealthcareProviderSuspense<
  TData = Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof auditEventsOfHealthcareProvider>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useAuditEventsOfHealthcareProviderSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const readFileData = (id: string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<string>({ url: `/portal-api/files/${id}`, method: 'GET', signal }, options);
};

export const getReadFileDataQueryKey = (id: string) => {
  return [`/portal-api/files/${id}`] as const;
};

export const useReadFileDataQueryOptions = <
  TData = Awaited<ReturnType<typeof readFileData>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadFileDataQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readFileData>>> = ({ signal }) =>
    readFileData(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadFileDataQueryResult = NonNullable<Awaited<ReturnType<typeof readFileData>>>;
export type ReadFileDataQueryError = ErrorType<unknown>;

export function useReadFileData<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadFileData<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadFileData<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadFileData<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadFileDataQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useReadFileDataSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof readFileData>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadFileDataQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readFileData>>> = ({ signal }) =>
    readFileData(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadFileDataSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof readFileData>>>;
export type ReadFileDataSuspenseQueryError = ErrorType<unknown>;

export function useReadFileDataSuspense<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadFileDataSuspense<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadFileDataSuspense<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadFileDataSuspense<TData = Awaited<ReturnType<typeof readFileData>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readFileData>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadFileDataSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const readPatientDataForValidation = (
  params: ReadPatientDataForValidationParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<string>({ url: `/portal-api/data-validation`, method: 'GET', params, signal }, options);
};

export const getReadPatientDataForValidationQueryKey = (params: ReadPatientDataForValidationParams) => {
  return [`/portal-api/data-validation`, ...(params ? [params] : [])] as const;
};

export const useReadPatientDataForValidationQueryOptions = <
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadPatientDataForValidationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readPatientDataForValidation>>> = ({ signal }) =>
    readPatientDataForValidation(params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadPatientDataForValidationQueryResult = NonNullable<
  Awaited<ReturnType<typeof readPatientDataForValidation>>
>;
export type ReadPatientDataForValidationQueryError = ErrorType<unknown>;

export function useReadPatientDataForValidation<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadPatientDataForValidation<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadPatientDataForValidation<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadPatientDataForValidation<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadPatientDataForValidationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useReadPatientDataForValidationSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadPatientDataForValidationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readPatientDataForValidation>>> = ({ signal }) =>
    readPatientDataForValidation(params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof readPatientDataForValidation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReadPatientDataForValidationSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof readPatientDataForValidation>>
>;
export type ReadPatientDataForValidationSuspenseQueryError = ErrorType<unknown>;

export function useReadPatientDataForValidationSuspense<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadPatientDataForValidationSuspense<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadPatientDataForValidationSuspense<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadPatientDataForValidationSuspense<
  TData = Awaited<ReturnType<typeof readPatientDataForValidation>>,
  TError = ErrorType<unknown>,
>(
  params: ReadPatientDataForValidationParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readPatientDataForValidation>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadPatientDataForValidationSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const readDataProjects = (
  params?: ReadDataProjectsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PortalDataProject[]>({ url: `/portal-api/data-projects`, method: 'GET', params, signal }, options);
};

export const getReadDataProjectsQueryKey = (params?: ReadDataProjectsParams) => {
  return [`/portal-api/data-projects`, ...(params ? [params] : [])] as const;
};

export const useReadDataProjectsQueryOptions = <
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadDataProjectsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readDataProjects>>> = ({ signal }) =>
    readDataProjects(params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadDataProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof readDataProjects>>>;
export type ReadDataProjectsQueryError = ErrorType<unknown>;

export function useReadDataProjects<TData = Awaited<ReturnType<typeof readDataProjects>>, TError = ErrorType<unknown>>(
  params: undefined | ReadDataProjectsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjects<TData = Awaited<ReturnType<typeof readDataProjects>>, TError = ErrorType<unknown>>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjects<TData = Awaited<ReturnType<typeof readDataProjects>>, TError = ErrorType<unknown>>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadDataProjects<TData = Awaited<ReturnType<typeof readDataProjects>>, TError = ErrorType<unknown>>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadDataProjectsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useReadDataProjectsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadDataProjectsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readDataProjects>>> = ({ signal }) =>
    readDataProjects(params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadDataProjectsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof readDataProjects>>>;
export type ReadDataProjectsSuspenseQueryError = ErrorType<unknown>;

export function useReadDataProjectsSuspense<
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | ReadDataProjectsParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjectsSuspense<
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjectsSuspense<
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadDataProjectsSuspense<
  TData = Awaited<ReturnType<typeof readDataProjects>>,
  TError = ErrorType<unknown>,
>(
  params?: ReadDataProjectsParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProjects>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadDataProjectsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const readDataProject = (id: string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<PortalDataProjectWithScopesDto>(
    { url: `/portal-api/data-projects/${id}`, method: 'GET', signal },
    options,
  );
};

export const getReadDataProjectQueryKey = (id: string) => {
  return [`/portal-api/data-projects/${id}`] as const;
};

export const useReadDataProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadDataProjectQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readDataProject>>> = ({ signal }) =>
    readDataProject(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadDataProjectQueryResult = NonNullable<Awaited<ReturnType<typeof readDataProject>>>;
export type ReadDataProjectQueryError = ErrorType<unknown>;

export function useReadDataProject<TData = Awaited<ReturnType<typeof readDataProject>>, TError = ErrorType<unknown>>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProject<TData = Awaited<ReturnType<typeof readDataProject>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProject<TData = Awaited<ReturnType<typeof readDataProject>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadDataProject<TData = Awaited<ReturnType<typeof readDataProject>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadDataProjectQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useReadDataProjectSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadDataProjectQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readDataProject>>> = ({ signal }) =>
    readDataProject(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ReadDataProjectSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof readDataProject>>>;
export type ReadDataProjectSuspenseQueryError = ErrorType<unknown>;

export function useReadDataProjectSuspense<
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjectSuspense<
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useReadDataProjectSuspense<
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useReadDataProjectSuspense<
  TData = Awaited<ReturnType<typeof readDataProject>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readDataProject>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useReadDataProjectSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const redirectByDataProjectId = (
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<Unit>(
    { url: `/portal-api/data-projects/${dataProjectId}/redirect`, method: 'GET', params, signal },
    options,
  );
};

export const getRedirectByDataProjectIdQueryKey = (dataProjectId: string, params: RedirectByDataProjectIdParams) => {
  return [`/portal-api/data-projects/${dataProjectId}/redirect`, ...(params ? [params] : [])] as const;
};

export const useRedirectByDataProjectIdQueryOptions = <
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRedirectByDataProjectIdQueryKey(dataProjectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof redirectByDataProjectId>>> = ({ signal }) =>
    redirectByDataProjectId(dataProjectId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type RedirectByDataProjectIdQueryResult = NonNullable<Awaited<ReturnType<typeof redirectByDataProjectId>>>;
export type RedirectByDataProjectIdQueryError = ErrorType<unknown>;

export function useRedirectByDataProjectId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useRedirectByDataProjectId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useRedirectByDataProjectIdQueryOptions(dataProjectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useRedirectByDataProjectIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRedirectByDataProjectIdQueryKey(dataProjectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof redirectByDataProjectId>>> = ({ signal }) =>
    redirectByDataProjectId(dataProjectId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof redirectByDataProjectId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RedirectByDataProjectIdSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof redirectByDataProjectId>>
>;
export type RedirectByDataProjectIdSuspenseQueryError = ErrorType<unknown>;

export function useRedirectByDataProjectIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useRedirectByDataProjectIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: RedirectByDataProjectIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useRedirectByDataProjectIdSuspenseQueryOptions(dataProjectId, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const authorize = (
  dataProjectId: string,
  params: AuthorizeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<Unit>(
    { url: `/portal-api/data-projects/${dataProjectId}/authorize`, method: 'GET', params, signal },
    options,
  );
};

export const getAuthorizeQueryKey = (dataProjectId: string, params: AuthorizeParams) => {
  return [`/portal-api/data-projects/${dataProjectId}/authorize`, ...(params ? [params] : [])] as const;
};

export const useAuthorizeQueryOptions = <TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthorizeQueryKey(dataProjectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authorize>>> = ({ signal }) =>
    authorize(dataProjectId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type AuthorizeQueryResult = NonNullable<Awaited<ReturnType<typeof authorize>>>;
export type AuthorizeQueryError = ErrorType<unknown>;

export function useAuthorize<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuthorize<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuthorize<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useAuthorize<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useAuthorizeQueryOptions(dataProjectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useAuthorizeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof authorize>>,
  TError = ErrorType<unknown>,
>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthorizeQueryKey(dataProjectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authorize>>> = ({ signal }) =>
    authorize(dataProjectId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type AuthorizeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authorize>>>;
export type AuthorizeSuspenseQueryError = ErrorType<unknown>;

export function useAuthorizeSuspense<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuthorizeSuspense<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useAuthorizeSuspense<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useAuthorizeSuspense<TData = Awaited<ReturnType<typeof authorize>>, TError = ErrorType<unknown>>(
  dataProjectId: string,
  params: AuthorizeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authorize>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useAuthorizeSuspenseQueryOptions(dataProjectId, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGrantsByDataProjectGrantId = (
  id: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DataProjectScopeRequestGrantsWithRelatedIds>(
    { url: `/portal-api/data-projects/grants/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGetGrantsByDataProjectGrantIdQueryKey = (id: string) => {
  return [`/portal-api/data-projects/grants/${id}`] as const;
};

export const useGetGrantsByDataProjectGrantIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGrantsByDataProjectGrantIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>> = ({ signal }) =>
    getGrantsByDataProjectGrantId(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetGrantsByDataProjectGrantIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>
>;
export type GetGrantsByDataProjectGrantIdQueryError = ErrorType<unknown>;

export function useGetGrantsByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGrantsByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetGrantsByDataProjectGrantIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useGetGrantsByDataProjectGrantIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGrantsByDataProjectGrantIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>> = ({ signal }) =>
    getGrantsByDataProjectGrantId(id, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGrantsByDataProjectGrantIdSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>
>;
export type GetGrantsByDataProjectGrantIdSuspenseQueryError = ErrorType<unknown>;

export function useGetGrantsByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrantsByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGrantsByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGrantsByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useGetGrantsByDataProjectGrantIdSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const redirectByDataProjectGrantId = (
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<Unit>(
    { url: `/portal-api/data-projects/grants/${dataProjectGrantId}/redirect`, method: 'GET', params, signal },
    options,
  );
};

export const getRedirectByDataProjectGrantIdQueryKey = (
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
) => {
  return [`/portal-api/data-projects/grants/${dataProjectGrantId}/redirect`, ...(params ? [params] : [])] as const;
};

export const useRedirectByDataProjectGrantIdQueryOptions = <
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRedirectByDataProjectGrantIdQueryKey(dataProjectGrantId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>> = ({ signal }) =>
    redirectByDataProjectGrantId(dataProjectGrantId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type RedirectByDataProjectGrantIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof redirectByDataProjectGrantId>>
>;
export type RedirectByDataProjectGrantIdQueryError = ErrorType<unknown>;

export function useRedirectByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useRedirectByDataProjectGrantId<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useRedirectByDataProjectGrantIdQueryOptions(dataProjectGrantId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useRedirectByDataProjectGrantIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRedirectByDataProjectGrantIdQueryKey(dataProjectGrantId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>> = ({ signal }) =>
    redirectByDataProjectGrantId(dataProjectGrantId, params, requestOptions, signal);

  const customOptions = transformQueryOptions({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RedirectByDataProjectGrantIdSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof redirectByDataProjectGrantId>>
>;
export type RedirectByDataProjectGrantIdSuspenseQueryError = ErrorType<unknown>;

export function useRedirectByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useRedirectByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useRedirectByDataProjectGrantIdSuspense<
  TData = Awaited<ReturnType<typeof redirectByDataProjectGrantId>>,
  TError = ErrorType<unknown>,
>(
  dataProjectGrantId: string,
  params: RedirectByDataProjectGrantIdParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof redirectByDataProjectGrantId>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = useRedirectByDataProjectGrantIdSuspenseQueryOptions(dataProjectGrantId, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
